import React from 'react'


const EspaciadorVertical = ({altura}) => (
  <div style={{
    height: altura,
    overflow: 'hidden',
  }}>
    &nbsp;
  </div>
)
export default EspaciadorVertical

