import React from 'react'
import {css} from '@emotion/core'

const videowrapper = css` 
  position: relative;
  padding-bottom: 56.25%; 
  margin-bottom: 4px;
`
const video = css` 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const Video=({src, title})=>(
  <div css={videowrapper}>
    <iframe
      css={video}
      src={src}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    ></iframe>
  </div>
)

export default Video
