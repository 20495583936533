import React from 'react'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Minipaginas from '../components/minipaginas'
import Video from '../components/video'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import FotosYCarrousel from '../components/fotos-y-carrousel'
import styled from '@emotion/styled'

const TituloDelCarrousel = styled.div`
  width: 90%;
  margin: 4rem auto;
  color: white;
  font-family: Satisfy;
  font-size: 48px;
  text-shadow: 1px 1px 2px #16455a;
`
const EnvoltorioDelCarrousel = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

// "Presentacion-susana-canel.wmv"

// #region GraphQL
export const query = graphql`
  {
    allListaSobreMiYaml {
      edges {
        node {
          nombre
          orgsrc
          dscrpt
          origen {
            childImageSharp {
              fixed(height: 100) {
                ...GatsbyImageSharpFixed
              }
              fluid(fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allSobremiYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion

// "Presentacion-susana-canel.wmv"
export default function SobreMi({ data }) {
  // eslint-disable-next-line no-unused-vars
  const toFixed = nombre => {
    const edges = data.allSobremiYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fixed = found.node.origen.childImageSharp.fixed
    return fixed
  }
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allSobremiYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }

  return (
    <Marco>
      <Container maxWidth="xl">
        <Minipaginas>
          <Minipagina>
            <FotoConLeyenda
              title="Te cuento sobre mí y mi emprendimiento turístico."
              leyenda="Te cuento sobre mí y mi emprendimiento turístico."
            >
              <Video
                src="https://www.youtube.com/embed/1CyzQUGWk2Y"
                title="Te cuento sobre mí y mi emprendimiento turístico."
              />
            </FotoConLeyenda>
          </Minipagina>
          <Minipagina>
            <TextoEnMinipagina>
              <h2>Soy Susana Canel.</h2>
              <p>
                Me apasiona viajar. A los 23 años hice mi primer viaje a Roma y enloquecí de amor
                por ella. Con el tiempo extendí mi amor a toda Italia pero guardando siempre un
                lugarcito muy especial en mi corazón para Roma. Roma: te amo!!! (no me pude
                contener, es un amor eterno) ¡La visité más de veinte veces y pienso seguir
                visitándola.
              </p>
              <p>
                Un día se me ocurrió que compartir mis experiencias me daría una gran alegría y
                también que podría aprovecharlas asesorándote para que puedas conocer esta
                maravillosa ciudad como yo lo he hecho y aún sigo descubriendo!!! No solamente
                asesorarte con Roma (mi especialidad) también con divertidos recorridos por
                pueblitos encantadores y por paisajes increíbles… En fin, todos aquellos lugares que
                he ido conociendo.
              </p>
            </TextoEnMinipagina>
          </Minipagina>
          <Minipagina>
            <TextoEnMinipagina>
              <h3>Matera (Basilicata).</h3>
              <p>
                Matera me dejó una honda impresión. No puedo dejar de recordarla. Es una ciudad
                mágica. Es especial, es única, se encuentra en medio de un paisaje impresionante.
              </p>
              <p>Es tan única que parece que detrás de mí hubiera un telón!!! No parece real.</p>
              <p>
                Matera está construida sobre una montaña rocosa y las casas están excavadas en la
                roca. Por eso ese color gris, es el color de la roca.
              </p>
              <p>
                Además el paisaje que la rodea es espectacular. Altas montañas rocosas, con mucho
                verde y profundos valles recorridos por riachuelos.
              </p>
              <p>
                Uno camina por el centro histórico y a toda hora se escuchan coros o música porque
                es la ciudad de la música. Llena de conservatorios.
              </p>
              <p>Fue elegida capital europea de la cultura 2019. Y bien que se lo merece.</p>
              <p>
                {' '}
                Aquí Mel Gibson filmó «La pasión de Cristo». ¿Por qué te parece que la habrá
                elegido?
              </p>
            </TextoEnMinipagina>
          </Minipagina>
          <Minipagina>
            <FotoConLeyenda
              zoom
              fluid={toFluid('imgsobremimaterajpg')}
              title="En la fascinante Matera."
              leyenda="En la fascinante Matera."
            />
          </Minipagina>
          <Minipagina>
            <FotoConLeyenda
              zoom
              fluid={toFluid('imgsobremiconmiinstructordekayakmardelplatajpg')}
              title="Invierno en Mar del Plata. Con mi instructor de kayak."
              leyenda="Con mi instructor de kayak. Invierno en Mar del Plata."
            />
          </Minipagina>
          <Minipagina>
            <TextoEnMinipagina>
              <h3>Con mi instructor de kayak. Mar del Plata en invierno.</h3>
              <p>
                Parece que hiciera calorcito por el solcito, pero no, cuando empezamos a remar, una
                mañana de julio, con niebla, había solo 0°C. La foto es al regreso, el sol un poco
                más alto y unos 3°C. Y se sentía mucho frío con el neoprene (que "funciona" en el
                agua, no en el aire, ja, ja). Pero quien me quita lo bailado. Inolvidable
                experiencia.
              </p>
              <p>
                Me encanta el invierno y adoro Mar del Plata. Me gusta ver a los muchachos que hacen
                surf todo el año. Me tenté ¿y, si aprendo surf? Por el momento ese proyecto quedó un
                poco postergado. Pero, quien dice...
              </p>
            </TextoEnMinipagina>
          </Minipagina>
          <Minipagina>
            <TextoEnMinipagina>
              <h3>Remando en kayak. Tigre.</h3> <p>Ahora sí en verano. </p>
              <p>
                El resto de las fotos, elegidas como para que me conozcas un poco, las he puesto en
                el carrousel y con las miniaturas por si quieres seleccionar alguna en particular.
              </p>
              <p>
                Te cuento, hay otro foto remando en kayak en Tigre. Es un deporte que me encanta, me
                da mucha paz.
              </p>
              <p>
                Luego he tenido dos bautismos de buceo en Mar del Plata, en invierno,
                lamentablemente no tengo fotos. Pero sí tengo de la tercera vez que bucee. Esta vez
                en otoño y en Porto Ercole (Monte Argentario, Toscana, Italia). Ahora, atardecer
                otoñal.
              </p>
            </TextoEnMinipagina>
          </Minipagina>
          <Minipagina>
            <FotoConLeyenda
              zoom
              fluid={toFluid('imgsobremiremandoenkayakTigrejpg')}
              title="Remando en kayak. Verano en Tigre."
              leyenda="Remando en kayak. Verano en Tigre."
            />
          </Minipagina>{' '}
          <Minipagina>
            <TextoEnMinipagina>
              Buceando en Porto Ercole. <p> Antes de la inmersión. </p>
              Buceando en Porto Ercole en otoño y cuando se ponía el sol.
              <p>
                El solcito...poniéndose. Las sombras...avanzando. Después de bucear, toda mojada y
                esperando que el buzo volviera ... Qué frío ¡Brrr! Tiritando.
              </p>
              <p> Al fondo se ve Porto Ercole, ya en sombras. </p>
              El después del otoñal buceo.
              <p>
                Después de bucear, cabello mojado, sin ducha caliente, sin toalla...no me queda más
                que reír.
              </p>
              Navegando en el Brivón. Haciendo equilibrio dentro del Istria. Timoneando en el
              Quitapenas. Navegando a Colonia en el Istria. Esperando el Pampero. Colonia. Comenzó
              el Pampero. Colonia. Velero escorado. Escalera fenicia. Capri. Italia. Tirando la
              monedita Fontana di Trevi.
            </TextoEnMinipagina>
          </Minipagina>
          <Minipagina>
            <FotoConLeyenda
              title="Viento Pampero en Colonia, Uruguay."
              leyenda="Viento Pampero en Colonia, Uruguay."
            >
              <Video
                src="https://www.youtube.com/embed/Dip9I7EHnbk"
                title="Viento Pampero en Colonia, Uruguay."
              />
            </FotoConLeyenda>
          </Minipagina>
          <Minipagina>
            <TextoEnMinipagina>
              <h3>En Colonia y con Pampero.</h3>
              <p>
                ¡En Colonia apenas a tiempo par amarrar y ver llegar el Pampero! La de blanco y rosa
                soy yo, volándome!!!!
              </p>
              <p>Después del carrousel de fotos te cuento más sobre mí.</p>
            </TextoEnMinipagina>
          </Minipagina>
        </Minipaginas>
        <EnvoltorioDelCarrousel>
          <TituloDelCarrousel>...aquí tienes mis fotos...</TituloDelCarrousel>
          <FotosYCarrousel edges={data.allListaSobreMiYaml.edges} />
        </EnvoltorioDelCarrousel>
        <Minipaginas>
          <Minipagina>
            <TextoEnMinipagina>
              <p style={{ textAlign: 'justify' }}>
                <strong> Soy muy curiosa</strong>, me gusta saber de todo, por eso{' '}
                <strong> me gusta viajar</strong>, conocer gente, interiorizarme por las costumbres
                de cada lugar. En la primera foto estoy en Matera, en la Región Basilicata. Esta
                ciudad es absolutamente increíble. Por algo la elegí como primera foto de mi blog.
              </p>
              <p style={{ textAlign: 'justify' }}>
                Además me encanta la{' '}
                <b>
                  historia antigua y medieval y la mitología principalmente la griega y la romana
                </b>
                . Me gusta muchísimo leer, sobre todo
                <strong> novelas históricas</strong>, porque me resulta una manera entretenida de
                aprender distintas culturas. Últimamente leo solamente libros en idioma italiano,
                más allá de que sea un placer para mí leer en ese idioma, quiero enriquecer mi
                vocabulario y no olvidarme lo que he estudiado. Trato de leer autores italianos, es
                mi manera de acercarme a la cultura italiana. Aunque he comprado libros traducidos
                de otros idiomas (¡nadie es perfecto!).
              </p>
              <p style={{ textAlign: 'justify' }}>
                También <strong> me encanta el cine</strong> y ¿adiviná qué películas me gusta ver?
                pues, ¡las italianas! Pero no soy excluyente. Soy también fanática del cine inglés y
                me gusta el francés. Desde mis 9 años que voy al cine una vez a la semana y casi
                siempre lo cumplí ¡Si habré visto películas! No llegan muchas películas españolas
                pero las que he visto también me han gustado mucho.
              </p>
              <p style={{ textAlign: 'justify' }}>
                Y <strong> me gusta escribir</strong>. Compartir mis experiencias, contar anécdotas
                y conversar!!!
              </p>
              <p style={{ textAlign: 'justify' }}>
                <strong> Entre mis pasiones se encuentra el mar</strong> y los
                <strong> deportes acuáticos</strong>, los que cuidan el medio ambiente.{' '}
                <strong> Nada que contamine!!!</strong> Ningún tipo de contaminación, incluyo una
                que para mí es importantísima y de la cual no se habla, la{' '}
                <strong> contaminación sonora</strong>. Odio los ruidos artificiales.
                <strong>
                  Me encanta escuchar el ruido del mar, del viento, de las olas al romper
                </strong>
                .
              </p>
              <p style={{ textAlign: 'justify' }}>
                En cuanto tengo la oportunidad voy al Tigre
                <strong> a remar en kayak</strong>. Cuando voy por un río tranquilo y sin gente me
                da un placer inmenso impulsarme con los remos, dejar de remar y permitir que el
                kayak se deslice sin hacer ruido. Puse algunas fotos de remadas por el Tigre. Pero
                también me encantaba ir a remar a Mar del Plata. Con mi instructor íbamos a 600 m de
                la costa, hemos ido de La Perla a playa Varese. También me enseñó a barrenar con el
                kayak. Todo muy divertido. Siempre en invierno. Puse una foto de una de una de esas
                remadas que comenzó con cero grados, julio en Mar del Plata. Después salió el sol,
                se fue la niebla y levantó un poco la temperatura.
              </p>
              <p style={{ textAlign: 'justify' }}>
                He hecho<strong> bautismo de buceo</strong> en tres oportunidades. Dos veces en Mar
                del Plata, siempre en invierno. Y la tercera en verano en Porto Ercole en Monte
                Argentario (Toscana Italia). Las fotos son de esta última vez. Era otoño y en cuanto
                bajó el sol comenzó a hacer mucho frío y yo no tenía ni toalla. Había ido a conocer
                Porto Ercole, caminaba cuando vi que se podía practicar buceo e improvisé!!! Qué
                frío, toda empapada y sin poderme secar. Pero me divertí mucho ¡El que quiere
                celeste, que le cueste!
              </p>
              <p style={{ textAlign: 'justify' }}>
                Con mayor frecuencia navego en velero.
                <strong> Me recibí de timonel de yate a vela</strong>
                <strong> y motor</strong>
                <strong> ¡Me gustan las olas, el viento y las tormentas! </strong>
                Me gusta el velero escorado e ir atada en la borda con lo pies colgando para hacer
                contrapeso ¡Y las ceñidas rabiosas! Y como
                <strong> me gusta el invierno</strong>, el curso de timonel lo hice en invierno. En
                varias navegaciones a Colonia, Uruguay, me tocó viento muy fuerte, 20 nudos o más y
                hasta una vez llegamos a amarrar cuando se desató el Pampero. Fue muy emocionante.
                Incluí fotos de distintas navegaciones y del Pampero en Colonia.
              </p>
            </TextoEnMinipagina>
          </Minipagina>
          <Minipagina>
            <TextoEnMinipagina>
              <p style={{ textAlign: 'justify' }}>
                No solamente me gusta el mar, también
                <strong> me gusta el cielo.</strong> Volar, en cualquier cosa, avión, por supuesto.
                Y no solamente, <strong> h</strong>e<strong> volado en parapente</strong> (con
                instructor) en dos oportunidades y enloquecí del placer. Esta experiencia fue
                fascinante. Increíble. El viento a 2000 m de altura. Las nubes ahí nomás.
              </p>
              <p style={{ textAlign: 'justify' }}>
                <strong> Y más tranqui&#8230;caminar y caminar! Subir y bajar&#8230;puf! </strong>
                En la foto estoy en la isla de Capri, bajé 600m desde la cima del monte Solaro hasta
                el nivel del mar, gran parte del recorrido por la escalera fenicia. Caminar, sí, me
                gusta, y mucho pero subir o bajar, no, por favor. Me canso mucho ¡Pero en Italia no
                me queda otra!
              </p>
              <p style={{ textAlign: 'justify' }}>
                <strong> Soy fanática de la fotografía</strong>. Me encanta sacar fotos. Nada
                especial, ni equipo ni nada, solamente para tener un recuerdo. Miles y{' '}
                <strong> miles de fotos</strong> de mis viajes.
                <strong> Amo la naturaleza</strong> (¡la necesitoooooo!) y los leones. Elegí como
                foto de portada de mi blog una con un león. Fue en mi primer viaje a Roma, fui al
                zoológico y había un fotógrafo profesional con un leoncito para sacar fotos a los
                interesados. Y yo, por supuesto que lo estaba. Así, en una sola foto reuní mis dos
                amores: Roma y los leones.
              </p>
              <p style={{ textAlign: 'justify' }}>
                Estoy
                <strong> muy preocupada por la contaminación ambiental</strong>. Si pudiera tomaría
                medidas drásticas en contra de los autos, ómnibus, camiones&#8230;es una larga
                lista. Con caminatas, bicicletas, subterráneos y trenes, estoy hecha. Los autos para
                contadas y excepcionales ocasiones y por supuesto eléctricos o cualquier cosa que no
                contamine.
              </p>
              <h3 style={{ textAlign: 'justify' }}>
                <strong> Estudios</strong>
              </h3>
              <p style={{ textAlign: 'left' }}>
                Estudié<strong> italiano</strong>, en la Asociación Dante Alighieri de Buenos Aires.
                Terminé los 4 cursos de
                <strong> Civiltà</strong> y 3 de
                <strong> perfeccionamiento de la lengua</strong>, me falta el último pero como estoy
                siempre viajando no puedo hacerlo.
              </p>
              <p style={{ textAlign: 'justify' }}>
                Mi curiosidad me ha movido a estudiar de todo. Primero me recibí de{' '}
                <strong> profesora de Química</strong>, después de
                <strong> Geógrafa Matemática</strong> y por último de
                <strong> Ing. Electrónica</strong>. Y mientras estudiaba esas carreras en simultáneo
                empezaba otras que lamentablemente he tenido que ir dejando. En el camino quedaron
                Geología, Análisis de Sistemas y Ciencias de la Computación ¿Y de qué trabajé?
                Bueno, un poco en cada cosa para finalmente dedicarme por entero a la docencia
                universitaria en Análisis Matemático II y en cátedras de Electrónica. Así que, a las
                pasiones anteriores se pueden agregar: <strong> estudiar y enseñar</strong>. Enseñar
                en general ¡Explicar cualquier materia!
              </p>
              <p style={{ textAlign: 'justify' }}>
                Ahora tengo esta empresa de asesoramiento turístico, doy clases particulares de
                Matemática, Física y Técnicas Digitales y dicto Cursos de VHDL (lenguaje descriptor
                de hardware).
              </p>
            </TextoEnMinipagina>
          </Minipagina>
        </Minipaginas>
      </Container>
    </Marco>
  )
}
