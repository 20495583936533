import React from 'react'
import Grid from '@material-ui/core/Grid'
import {css} from '@emotion/core'

const estilo = css`
 padding: 0 24px;
`
const palidez = css`
  background-color: #ffffff80;
  border-radius: 8px;
`
const repalidez = css`
  background-color: #ffffffd0;
  border-radius: 8px;
`

const Minipagina = ({children, palida, repalida, todoElAncho}) => {
  const sm = 12
  const md = todoElAncho ? 12 : 8
  const lg = todoElAncho ? 12 : 6
  return <Grid sm={sm} md={md} lg={lg} item>
    <div css={palida ? (repalida ? [estilo, repalidez] : [estilo, palidez]) : estilo}>
      <div css={repalida ? palidez : {}}>
        {children}
      </div>
    </div>
  </Grid >
}
export default Minipagina
